<template>
  <div v-if="minDeposit" class="bonuses-min-deposit">
    <div class="bonuses-min-deposit__row">
      <span class="bonuses-min-deposit__label">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'bonuses.minDeposit') }}:
      </span>
      <span class="bonuses-min-deposit__amount">{{ formatAmount(minDeposit.currency, minDeposit.amount) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
  import type { IBonus } from '~/types';

  const props = defineProps<{
    bonusInfo: IBonus;
  }>();

  const globalStore = useGlobalStore();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = storeToRefs(globalStore);
  const { getContent } = useProjectMethods();

  const { getMinBonusDeposit, formatAmount } = useProjectMethods();
  const minDeposit = computed(() => getMinBonusDeposit(props.bonusInfo));
</script>

<style src="~/assets/styles/components/bonuses/min-deposit.scss" lang="scss" />
